import "./index.css";
import { ReactComponent as Logo } from "../src/logo.svg";
import { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Footer from "./components/Footer";

import homelocation1 from '../src/images/homeLocation/homelocation1.jpg';
import homelocation2 from '../src/images/homeLocation/homelocation2.jpg';
import homelocation3 from '../src/images/homeLocation/homelocation3.jpg';
import homelocation4 from '../src/images/homeLocation/homelocation4.jpg';
import homelocation5 from '../src/images/homeLocation/homelocation5.jpeg';
import homelocation6 from '../src/images/homeLocation/homelocation6.jpeg';
import homelocation7 from '../src/images/homeLocation/homelocation7.jpg';
import homelocation8 from '../src/images/homeLocation/homelocation8.jpg';
import homelocation9 from '../src/images/homeLocation/homelocation9.jpg';
import homelocation10 from '../src/images/homeLocation/homelocation10.jpg';
import homelocation11 from '../src/images/homeLocation/homelocation11.png';

function PickUpAtHomeLocation() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;

        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <header>
                <div className="container mx-auto flex items-center justify-between p-4">
                    <div className="flex flex-row items-center">
                        <Logo />
                        <p className={`rajdhani font-semibold text-2xl pl-2 leading-none`}>
                            KOUSEKI <br /> AUTO RENTAL
                        </p>
                    </div>
                    <nav>
                        <ul className="flex space-x-4">
                            <li>
                                <a href="/" className="inter pl-7 text-base" target='_blank'>
                                    Home
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <div className="container mx-auto max-w-6xl mt-12">
            <div className="flex flex-wrap justify-center">
    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("PublicTransit")}
    >
        <span className="relative z-10">
            Pick Up @YVR
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("UberDriving")}
    >
        <span className="relative z-10">
            Pick Up @Marine Dr.
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("Returning")}
    >
        <span className="relative z-10">
            Return @YVR
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("Direction")}
    >
        <span className="relative z-10">
            Return @Marine Dr.
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

</div>
            </div>

            <div id="PublicTransit" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">Pick Up the car @YVR- <span className="">(Domestic/International)</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation1} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Domestic Step 1: Head out to the YVR station <span className="text-base md:text-xl lg:text-2xl font-medium"><br/>(swipe to right to see the next step! or for internatinal flight)</span></span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation10} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">International Step 1: Head out to the YVR station <span className="text-base md:text-xl lg:text-2xl font-medium"></span> </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation2} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Buy 2 zone ticket <span className="text-base md:text-xl lg:text-2xl font-medium"></span> </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation3} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 3: Once you arrive at Marine Drive Station, take any elevator to reach P3</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation4} />
                            <div className="flex flex-col">
                                <span className="text-base md:text-xl lg:text-3xl font-light inter">Arrive! #569-571</span>
                            </div>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation9} />
                            <div className="flex flex-col">
                                <span className="text-base md:text-xl lg:text-3xl font-light inter">Once you arrive and locate your car, take a selfie with it and send it to us! We'll then send you a code to unlock the keybox for you to get the car key!</span>
                            </div>
                    </div>
                </Slide>
            </div>

            <div id="UberDriving" className="py-16 px-8">
                <div id="PublicTransit" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">Pick Up the car @Marine Dr.- <span className="">(Domestic/International/Local)</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation1} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Domestic Step 1: Head out to the YVR station <span className="text-base md:text-xl lg:text-2xl font-medium"><br/>(swipe to right to see the next step! for internatinal flight or local)</span></span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation10} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">International Step 1: Head out to the YVR station <span className="text-base md:text-xl lg:text-2xl font-medium"></span> </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation2} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Buy 2 zone ticket <span className="text-base md:text-xl lg:text-2xl font-medium"></span> </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation3} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 3: Once you arrive at Marine Drive Station, take any elevator to reach P3</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation11} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Local: Take Uber/Lift/Transit</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation4} />
                            <div className="flex flex-col">
                                <span className="text-base md:text-xl lg:text-3xl font-light inter">Arrive! #569-571</span>
                            </div>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={homelocation9} />
                            <div className="flex flex-col">
                                <span className="text-base md:text-xl lg:text-3xl font-light inter">Once you arrive and locate your car, take a selfie with it and send it to us! We'll then send you a code to unlock the keybox for you to get the car key!</span>
                            </div>
                    </div>
                </Slide>
            </div>
            </div>

            <div id="Returning" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6"><span className="font-medium">For Returning</span></p>
                {/* <Slide autoplay={false}> */}
                    <div className="">
                        <img className="rounded-2xl" src={homelocation4} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Please return on our reserved spots: #569-571 </span>
                    </div>
                {/* </Slide> */}
            </div>

            <div id="Direction" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6"> <span className="font-medium">  Get Direction </span></p>
                    <div className="">
                            <div className="">
                                <span className="flex md:flex-row">
                                <a href="https://maps.app.goo.gl/xYvw1y6TB4HYbfui8" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on google
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                
                                <a href="https://maps.apple.com/?address=460%20SW%20Marine%20Dr,%20Vancouver%20BC%20V5X%200C4,%20Canada&auid=17195680064276959739&ll=49.209886,-123.116212&lsp=9902&q=Impark" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on apple
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                </span>
                            </div>
                    </div>
            </div>
            <Footer />


            {isVisible && (
                <button 
                    onClick={scrollToTop} 
                    className="fixed bottom-4 right-4 footerColour text-white p-3 rounded-full shadow-lg transition-opacity duration-300 hover:bg-white hover:text-black">
                    ↑
                </button>
            )}

        </div>
    )
}

export default PickUpAtHomeLocation;