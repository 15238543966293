import "./index.css";
import { ReactComponent as Logo } from "../src/logo.svg";
import { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import Footer from "./components/Footer";

import direction1 from '../src/images/passangerPickUps/direction1.png';
import direction2 from '../src/images/passangerPickUps/direction2.png';
import direction3 from '../src/images/passangerPickUps/direction3.png';
import direction4 from '../src/images/passangerPickUps/direction4.png';
import direction5 from '../src/images/passangerPickUps/direction5.png';
import direction6 from '../src/images/passangerPickUps/direction6.png';
import direction7 from '../src/images/passangerPickUps/direction7.png';
import direction8 from '../src/images/passangerPickUps/direction8.png';


function DirectionToParkinglots() {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;

        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    useEffect(() => {
        const scrollToSection = (sectionId) => {
            const targetSection = document.getElementById(sectionId);
            if (targetSection) {
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: "smooth",
                });
            }
        };
        window.scrollToSection = scrollToSection;
    }, []);

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <header>
                <div className="container mx-auto flex items-center justify-between p-4">
                    <div className="flex flex-row items-center">
                        <Logo />
                        <p className={`rajdhani font-semibold text-2xl pl-2 leading-none`}>
                            KOUSEKI <br /> AUTO RENTAL
                        </p>
                    </div>
                    <nav>
                        <ul className="flex space-x-4">
                            <li>
                                <a href="/" className="inter pl-7 text-base" target='_blank'>
                                    Home
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <div className="container mx-auto max-w-6xl mt-12">
            <div className="flex flex-wrap justify-center">
    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("InternationalFlights")}
    >
        <span className="relative z-10">
            By Public Transit
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("DomesticFlights")}
    >
        <span className="relative z-10">
            By Driving/
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <button
        className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        onClick={() => window.scrollToSection("Direction")}
    >
        <span className="relative z-10">
            Direction
        </span>
        <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
    </button>

    <a href="https://www.yvr.ca/en/passengers/transportation/airport-south">
        <button
            className="inter my-2 text-xl font-extralight w-52 h-14 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2"
        >
            <span className="relative z-10">
                Shuttle Bus Schedule
            </span>
            <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
        </button>
    </a>
</div>
            </div>

            <div id="InternationalFlights" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">How to get to the our parking lots - <span className="font-medium">By Public Transit</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={direction6} />
                        <span className="text-base md:text-2xl lg:text-3xl font-light inter">This is your destination: Our pick up spot! <span className="text-base md:text-xl lg:text-2xl font-medium">(Parking spot: 238 - 258)</span> <br/> (swipe to right to see the next step!)</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction1} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 1: Take the free shuttle bus from Internationl Terminal <span className="text-base md:text-xl lg:text-2xl font-medium">(Photo shows the bus stop)</span></span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction2} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 1: Take the free shuttle bus from Domestic Terminal<span className="text-base md:text-xl lg:text-2xl font-medium">(Photo shows the bus stop)</span> </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction3} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Arrive at the south terminal</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction5} />
                            <div className="flex flex-col">
                                <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 3: Follow this direction</span>
                                <span className="flex md:flex-row flex-col">
                                <a href="https://maps.app.goo.gl/VFG9udNeBuqRvcRPA" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on google
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                
                                <a href="https://maps.apple.com/?address=4580%20Cowley%20Cres,%20Richmond%20BC%20V7B%201B8,%20Canada&ll=49.178638,-123.166225&q=4580%20Cowley%20Cres" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on apple
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                </span>
                            </div>
                    </div>
                </Slide>
            </div>

            <div id="DomesticFlights" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">How to get to the our parking lots - <span className="font-medium">By Driving</span></p>
                <Slide autoplay={false}>
                    <div className="">
                        <img className="rounded-2xl" src={direction6} />
                        <span className="text-base md:text-2xl lg:text-3xl font-light inter">This is your destination: Our pick up spot!  <span className="text-base md:text-xl lg:text-2xl font-medium">(Parking spot: 238 - 258)</span> <br/> (swipe to right to see the next step!)</span>
                        <div className="flex flex-col">
                                <span className="flex md:flex-row flex-col">
                                <a href="https://maps.app.goo.gl/VFG9udNeBuqRvcRPA" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on google
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                
                                <a href="https://maps.apple.com/?address=4580%20Cowley%20Cres,%20Richmond%20BC%20V7B%201B8,%20Canada&ll=49.178638,-123.166225&q=4580%20Cowley%20Cres" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on apple
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                </span>
                            </div>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction7} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 1: As you get close, you'll see a white building on your right </span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction4} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 2: Drive In!</span>
                    </div>
                    <div className="">
                        <img className="rounded-2xl" src={direction8} />
                        <span className="text-base md:text-xl lg:text-3xl font-light inter">Step 3: After turning left and keep going till the end </span>
                    </div>
                </Slide>
            </div>


            <div id="Direction" className="py-16 px-8">
                <p className="text-center md:text-2xl text-xl inter font-light pb-6">How to get to our parking lots - <span className="font-medium"> From South Terminal </span></p>
                    <div className="">
                        <img className="rounded-2xl" src={direction5} />
                            <div className="flex flex-col">
                                <span className="flex md:flex-row flex-col">
                                <a href="https://maps.app.goo.gl/VFG9udNeBuqRvcRPA" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on google
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                
                                <a href="https://maps.apple.com/?address=4580%20Cowley%20Cres,%20Richmond%20BC%20V7B%201B8,%20Canada&ll=49.178638,-123.166225&q=4580%20Cowley%20Cres" target="_blank"><button className="inter rounded-xl my-2 text-base font-extralight w-52 h-12 border border-white relative overflow-hidden group bg-slate-900 hover:text-black hover:bg-white text-white transition duration-300 md:mr-2">
                                    <span className="relative z-10">
                                    get direction on apple
                                    </span>
                                    <span className="absolute inset-0 bg-gray-100 text-black transform translate-y-full transition-transform duration-300 ease-in-out group-hover:translate-y-0"></span>
                                </button></a>
                                </span>
                            </div>
                    </div>
            </div>
            <Footer />


            {isVisible && (
                <button 
                    onClick={scrollToTop} 
                    className="fixed bottom-4 right-4 footerColour text-white p-3 rounded-full shadow-lg transition-opacity duration-300 hover:bg-white hover:text-black">
                    ↑
                </button>
            )}

        </div>
    )
}

export default DirectionToParkinglots;